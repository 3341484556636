/* BASIC APP CONSTANTS */
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const APP_COOKIE_NAME = "APP_COOKIE_NAME";
export const USER_COOKIE_NAME = "USER_COOKIE_NAME";
export const PASS_COOKIE_NAME = "PASS_COOKIE_NAME";
export const USER_ID_COOKIE_NAME = "USER_ID_COOKIE_NAME";
export const SET_CURRENT_ROUTE = "SET_CURRENT_ROUTE";
export const SET_SIDE_MENU_STATE = "SET_SIDE_MENU_STATE";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const SHOW_ACTION_MENU = "SHOW_ACTION_MENU";
export const HIDE_ACTION_MENU = "HIDE_ACTION_MENU";
export const SHOW_BOTTOM_TOOLBAR = "SHOW_BOTTOM_TOOLBAR";
export const HIDE_BOTTOM_TOOLBAR = "HIDE_BOTTOM_TOOLBAR";
export const SET_USER_LIST = "SET_USER_LIST";
export const EDIT_DISABLED_BUTTONS = "EDIT_DISABLED_BUTTONS";
export const SET_LOGS_LIST = "SET_LOGS_LIST";
export const THEME = "THEME";

/* <---- BASIC APP CONSTANTS ----> */

/* SOCKET CONSTANTS */
export const CONNECTED = "connected";
export const DISCONNECTED = "disconnected";
/* <---- SOCKET CONSTANTS ----> */

/* CONFIGURATION */
export const CONFIGURATION_FORM_TOOGLE_LOADING =
  "CONFIGURATION_FORM_TOOGLE_LOADING";
export const SET_CONFIGURATION = "SET_CONFIGURATION";
/* <---- CONFIGURATION ----> */

/* USER */
export const SET_PROFILE = "SET_PROFILE";
/* <---- USER ----> */

/* USERS */
export const ADD_NEW_USER = "ADD_NEW_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const OPEN_USERS_FORM = "OPEN_USERS_FORM";
export const CLOSE_USERS_FORM = "CLOSE_USERS_FORM";
export const EDIT_SELECTED_USER = "EDIT_SELECTED_USER";
export const USERS_FORM_TOOGLE_LOADING = "USERS_FORM_TOOGLE_LOADING";
/* <---- USERS ----> */

/* BOOKTYPE */
export const SET_BOOKTYPE_LIST = "SET_BOOKTYPE_LIST";
export const ADD_NEW_BOOKTYPE = "ADD_NEW_BOOKTYPE";
export const UPDATE_BOOKTYPE = "UPDATE_BOOKTYPE";
export const DELETE_BOOKTYPE = "DELETE_BOOKTYPE";
export const OPEN_BOOKTYPE_FORM = "OPEN_BOOKTYPE_FORM";
export const CLOSE_BOOKTYPE_FORM = "CLOSE_BOOKTYPE_FORM";
export const EDIT_SELECTED_BOOKTYPE = "EDIT_SELECTED_BOOKTYPE";
export const BOOKTYPE_FORM_TOOGLE_LOADING = "BOOKTYPE_FORM_TOOGLE_LOADING";
/* <---- BOOKTYPE ----> */

/* BOOKING */
export const SET_BOOKING_LIST = "SET_BOOKING_LIST";
export const ADD_NEW_BOOKING = "ADD_NEW_BOOKING";
export const UPDATE_BOOKING = "UPDATE_BOOKING";
export const DELETE_BOOKING = "DELETE_BOOKING";
export const OPEN_BOOKING_FORM = "OPEN_BOOKING_FORM";
export const CLOSE_BOOKING_FORM = "CLOSE_BOOKING_FORM";
export const EDIT_SELECTED_BOOKING = "EDIT_SELECTED_BOOKING";
export const BOOKING_FORM_TOOGLE_LOADING = "BOOKING_FORM_TOOGLE_LOADING";
/* <---- BOOKING ----> */

/* COURSE */
export const SET_COURSE_LIST = "SET_COURSE_LIST";
export const ADD_NEW_COURSE = "ADD_NEW_COURSE";
export const UPDATE_COURSE = "UPDATE_COURSE";
export const DELETE_COURSE = "DELETE_COURSE";
export const OPEN_COURSE_FORM = "OPEN_COURSE_FORM";
export const CLOSE_COURSE_FORM = "CLOSE_COURSE_FORM";
export const EDIT_SELECTED_COURSE = "EDIT_SELECTED_COURSE";
export const COURSE_FORM_TOOGLE_LOADING = "COURSE_FORM_TOOGLE_LOADING";
/* <---- COURSE ----> */

/* ROUTES */
export const SET_ALL_ROUTES = "SET_ALL_ROUTES";
export const UPDATE_ROUTE = "UPDATE_ROUTE";
/* <---- ROUTES ----> */

/* ALERT */
export const SET_ALERT_LIST = "SET_ALERT_LIST";
export const ADD_NEW_ALERT = "ADD_NEW_ALERT";
export const UPDATE_ALERT = "UPDATE_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";
export const OPEN_ALERT_FORM = "OPEN_ALERT_FORM";
export const CLOSE_ALERT_FORM = "CLOSE_ALERT_FORM";
export const EDIT_SELECTED_ALERT = "EDIT_SELECTED_ALERT";
export const ALERT_FORM_TOOGLE_LOADING = "ALERT_FORM_TOOGLE_LOADING";
/* <---- ALERT ----> */

/* CLASSROOM */
export const SET_CLASSROOM_LIST = "SET_CLASSROOM_LIST";
export const ADD_NEW_CLASSROOM = "ADD_NEW_CLASSROOM";
export const UPDATE_CLASSROOM = "UPDATE_CLASSROOM";
export const DELETE_CLASSROOM = "DELETE_CLASSROOM";
export const OPEN_CLASSROOM_FORM = "OPEN_CLASSROOM_FORM";
export const CLOSE_CLASSROOM_FORM = "CLOSE_CLASSROOM_FORM";
export const EDIT_SELECTED_CLASSROOM = "EDIT_SELECTED_CLASSROOM";
export const CLASSROOM_FORM_TOOGLE_LOADING = "CLASSROOM_FORM_TOOGLE_LOADING";
/* <---- CLASSROOM ----> */

/* RESOURCE */
export const SET_RESOURCE_LIST = "SET_RESOURCE_LIST";
export const ADD_NEW_RESOURCE = "ADD_NEW_RESOURCE";
export const UPDATE_RESOURCE = "UPDATE_RESOURCE";
export const DELETE_RESOURCE = "DELETE_RESOURCE";
export const OPEN_RESOURCE_FORM = "OPEN_RESOURCE_FORM";
export const CLOSE_RESOURCE_FORM = "CLOSE_RESOURCE_FORM";
export const EDIT_SELECTED_RESOURCE = "EDIT_SELECTED_RESOURCE";
export const RESOURCE_FORM_TOOGLE_LOADING = "RESOURCE_FORM_TOOGLE_LOADING";
/* <---- RESOURCE ----> */

/* SUBJECT */
export const SET_SUBJECT_LIST = "SET_SUBJECT_LIST";
export const ADD_NEW_SUBJECT = "ADD_NEW_SUBJECT";
export const UPDATE_SUBJECT = "UPDATE_SUBJECT";
export const DELETE_SUBJECT = "DELETE_SUBJECT";
export const OPEN_SUBJECT_FORM = "OPEN_SUBJECT_FORM";
export const CLOSE_SUBJECT_FORM = "CLOSE_SUBJECT_FORM";
export const EDIT_SELECTED_SUBJECT = "EDIT_SELECTED_SUBJECT";
export const SUBJECT_FORM_TOOGLE_LOADING = "SUBJECT_FORM_TOOGLE_LOADING";
/* <---- SUBJECT ----> */
